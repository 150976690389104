<router-outlet></router-outlet>

<div *ngIf="notLanding" class="controls">
  <div class="cheese-icon-container">
    <a class="cheese-icon" [routerLink]="cheesesRouterLink"></a>
  </div>
  <a class="instagram-icon" [href]="instagram" target="_blank"></a>
  <a class="facebook-icon" [href]="facebook" target="_blank"></a>
  <a (click)="toggleMenu()" class="material-symbols-outlined menu-icon"></a>
</div>

<ca-menu (dismissed)="toggleMenu()"></ca-menu>
