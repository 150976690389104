import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BruggeKaasState } from '../../store';
import { CaSubscriber } from '@ca/ca-utils';
import { selectHomeData, selectLanguage } from '../../store/selectors';
import { CheeseData, MomentData, PageDataBase } from '../../models';
import { LoadHomeData } from '../../store/home.reducer';
import { environment } from '../../../environments/environment';
import { SeoService } from '../../services/seo.service';

@Component({
    selector: 'ca-home',
    templateUrl: './home.component.html',
    standalone: false
})
export class HomeComponent implements OnInit, OnDestroy {
  mediaUrl = environment.apiBaseUrl;
  title?: string;
  text?: string;
  highlightedItems: HomeBlock[] = [];
  banner: PageDataBase['banner'];
  private sub = new CaSubscriber();

  constructor(private store: Store<BruggeKaasState>, private seo: SeoService) {}

  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectLanguage), () =>
      this.store.dispatch(LoadHomeData())
    );
    this.sub.subscribe(this.store.select(selectHomeData), {
      next: (data) => {
        this.seo.setMeta(data);

        this.title = data.title;
        this.text = data.text;
        this.banner = data.banner;

        this.highlightedItems = [];
        if (data.moments)
          data.cheeses.forEach((d, i) => {
            this.highlightedItems.push(i % 2 === 0 ? d : data.moments[i]);
            this.highlightedItems.push(i % 2 === 0 ? data.moments[i] : d);
          });
        else if (data.cheeses)
          data.cheeses.forEach((d, i) => {
            this.highlightedItems.push(d);
          });
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}

export type HomeBlock = MomentBlock | CheeseBlock;
export type MomentBlock = MomentData;
export type CheeseBlock = CheeseData;
