import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppError,
  GeneralDataLoaded,
  LoadGeneralData,
  SetLanguage,
  SetRegion,
} from './general.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { Route, Router } from '@angular/router';
import { GeneralData } from '../models';
import { PageID } from '../models';
import { HomeComponent } from '../pages/home/home.component';
import { MomentDetailComponent } from '../pages/moment-detail/moment-detail.component';
import { CheeseDetailComponent } from '../pages/cheese-detail/cheese-detail.component';
import { CheesesListComponent } from '../pages/cheeses-list/cheeses-list.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { LandingComponent } from '../pages/landing/landing.component';
import { RecipesListComponent } from '../pages/recipes-list/recipes-list.component';
import { SustainabilityComponent } from '../pages/sustainability/sustainability.component';
import { RecipeDetailComponent } from '../pages/recipe-detail/recipe-detail.component';
import { DisclaimerComponent } from '../pages/disclaimer/disclaimer.component';
import { PrivacyAndCookiesComponent } from '../pages/privacy-and-cookies/privacy-and-cookies.component';
import { firstValueFrom, map, switchMap } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class GeneralEffects implements OnDestroy {
  private sub = new CaSubscriber();

  setLanguage$ = this.actions$.pipe(ofType(SetLanguage));
  setRegion$ = this.actions$.pipe(ofType(SetRegion));
  load$ = this.actions$.pipe(ofType(LoadGeneralData));
  loaded$ = this.actions$.pipe(ofType(GeneralDataLoaded));

  constructor(private actions$: Actions, private router: Router, private api: ApiService) {}

  onLoaded$ = createEffect(
    () =>
      this.loaded$.pipe(
        map(({ data }) => {
          const config = ConfigureRoutes(data.pages);
          this.router.resetConfig(config);
        })
      ),
    { dispatch: false }
  );

  onSetLang$ = createEffect(
    () =>
      this.setLanguage$.pipe(
        map(({ language }) => {
          localStorage.setItem('language', language);
        })
      ),
    { dispatch: false }
  );

  onSetRegion$ = createEffect(
    () => this.setRegion$.pipe(map(({ region }) => localStorage.setItem('region', region))),
    { dispatch: false }
  );

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getGeneral())
          .then((data) => GeneralDataLoaded({ data }))
          .catch((error) => AppError({ error }))
      )
    )
  );
  onSetLanguage$ = createEffect(() => this.setLanguage$.pipe(map(() => LoadGeneralData())));
}

export function ConfigureRoutes(pages: GeneralData['pages']) {
  const routes: Route[] = [];
  pages.forEach((page) => {
    if (page.routeParam === false) {
      const route: Route = {
        ...GetComponentForPage(page.id),
        path: page.metaUrl,
        title: page.metaTitle,
        data: {
          id: page.id,
          metaUrl: page.metaUrl,
        },
      };
      routes.push(route);
    } else {
      const rootComponent = GetComponentForPage(page.id);
      const root: Route = {
        path: page.metaUrl,
        title: page.metaTitle,
        data: {
          id: page.id,
          metaUrl: page.metaUrl,
        },
      };

      const data = GetComponentForSubPage(page.id);
      if (data) {
        const child: Route = {
          ...data,
          path: ':metaUrl',
          title: page.metaTitle,
          data: {
            id: page.id,
            metaUrl: page.metaUrl,
          },
        };
        const rootSub: Route = {
          ...rootComponent,
          path: '',
          pathMatch: 'full',
          data: {
            id: page.id,
            metaUrl: page.metaUrl,
          },
        };
        root.children = [child, rootSub];
        routes.push(root);
      } else throw new Error(`NO SUB PAGE CONFIG FOR PageID: ${page.id} (${page.metaTitle})`);
    }
  });
  routes.push({
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  });
  return routes;
}

function GetComponentForSubPage(pageID: PageID): Pick<Route, 'canActivate' | 'component'> | void {
  switch (pageID) {
    case PageID.CHEESES:
      return { component: CheeseDetailComponent };

    case PageID.MOMENT_DETAIL:
      return { component: MomentDetailComponent };

    case PageID.RECIPES:
    case PageID.RECIPE_DETAIL:
      return { component: RecipeDetailComponent };

    default:
      break;
  }
}

function GetComponentForPage(pageID: PageID): Pick<Route, 'canActivate' | 'component'> {
  switch (pageID) {
    case PageID.HOME:
      return { component: HomeComponent };

    case PageID.CHEESES:
      return { component: CheesesListComponent };

    case PageID.CONTACT:
      return { component: ContactComponent };

    case PageID.LANDING:
      return { component: LandingComponent };

    case PageID.RECIPES:
      return { component: RecipesListComponent };

    case PageID.SUSTAINABILITY:
      return { component: SustainabilityComponent };

    case PageID.MOMENTS:
      return { component: MomentDetailComponent };

    case PageID.DISCLAIMER:
      return { component: DisclaimerComponent };

    case PageID.PRIVACY_AND_COOKIES:
      return { component: PrivacyAndCookiesComponent };

    default:
      throw new Error('PageID not valid: ' + pageID);
  }
}
