<ca-header [title]="(data$ | async)?.title"></ca-header>
<div class="filters">
  <div class="select">
    <select
      *ngIf="cheeseFilters$ | async as cheeses"
      id="standard-select"
      [(ngModel)]="cheeseFilter"
      (ngModelChange)="filterChange()">
      <option [selected]="cheeseFilter === null" [value]="null">
        {{ cheesesSelectHint$ | async }}
      </option>
      <option *ngFor="let cheese of cheeses" [value]="cheese.id">
        {{ cheese.name }}
      </option>
    </select>
  </div>

  <ng-container *ngIf="momentFilters$ | async as moments">
    <div class="select" *ngIf="moments.length > 0">
      <select id="standard-select" [(ngModel)]="momentFilter" (ngModelChange)="filterChange()">
        <option [selected]="momentFilter === null" [value]="null">
          {{ momentSelectHint$ | async }}
        </option>
        <option *ngFor="let moment of moments" [value]="moment.id">{{ moment.name }}</option>
      </select>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="filtered$ | async as recipes">
  <div class="recipes-list">
    <a *ngFor="let recipe of recipes" class="recipes-list-item" [routerLink]="recipe.routerLink">
      <img [alt]="recipe.image.alt" [src]="base + recipe.image.src" />
      <div class="info">
        <h4>
          <span *ngFor="let t of recipe.recipeTypes">{{ t.name }} </span>
        </h4>
        <h3>{{ recipe.name }}</h3>
        <div class="cooking-time" *ngIf="recipe.preparationTime > 0">
          <span class="material-symbols-outlined">schedule</span>
          <p>{{ recipe.preparationTime }} min</p>
        </div>
      </div>
    </a>
  </div>
</ng-container>
