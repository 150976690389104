import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../api.service';
import { Store } from '@ngrx/store';
import { GeneralDataLoaded, SetLanguage, SetRegion } from '../../store/general.reducer';
import { Region, SupportedLanguage } from '../../models';
import { LandingDataLoaded } from '../../store/landing.reducer';
import { ConfigureRoutes } from '../../store/general.effects';
import { Router } from '@angular/router';
@Injectable()
export class InitializerService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private store: Store,
    private router: Router
  ) {}
  /**
   * Gets the language from the navigator object.
   * @returns the user language
   */
  getLang() {
    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
  }

  async init(): Promise<void> {
    // TRY to get region and language from session
    const storedRegion = localStorage.getItem('region');
    const storedLanguage = localStorage.getItem('language');
    if (
      storedRegion &&
      ['US', 'World'].findIndex((s) => s === storedRegion.toString()) &&
      storedLanguage &&
      ['nl', 'fr', 'en', 'es'].findIndex((l) => l === storedLanguage.toString())
    ) {
      this.store.dispatch(SetRegion({ region: storedRegion as Region }));
      this.store.dispatch(SetLanguage({ language: storedLanguage as SupportedLanguage }));
      await this.LoadGeneral();
      await this.LoadLanding();
      return;
    } else {
      const country = await this.getCountry();
      const isUS = country === 'US';
      const region = isUS ? 'US' : 'world';
      this.store.dispatch(SetRegion({ region }));
      const language: SupportedLanguage = getDefaultLanguageForCountry(country);
      this.store.dispatch(SetLanguage({ language }));
      await this.LoadGeneral();
      await this.LoadLanding();
      // TODO: check here which page is loaded
      return;
    }
  }

  private async LoadGeneral() {
    await firstValueFrom(this.api.getGeneral()).then((data) => {
      const config = ConfigureRoutes(data.pages);
      this.router.resetConfig(config);
      this.store.dispatch(GeneralDataLoaded({ data }));
    });
  }
  private async LoadLanding() {
    await firstValueFrom(this.api.getLanding()).then((data) => {
      this.store.dispatch(LandingDataLoaded({ data }));
    });
  }

  /**
   * Gets country from external service "api.country.is"
   * @returns a country code.
   */
  async getCountry() {
    try {
      const { country } = await firstValueFrom(
        this.http.get<{ ip: string; country: string }>('https://api.country.is/')
      );
      return country;
    } catch (err) {
      console.error('Client blocked country API');
      return null;
    }
  }
}

export function getDefaultLanguageForCountry(country: string | null): SupportedLanguage {
  switch (country) {
    case 'NL':
    case 'BE':
      return 'nl';
    case 'FR':
      return 'fr';
    case 'ES':
      return 'es';
    default:
      return 'en';
  }
}
