import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ca-carousel',
    template: `<div class="container">
    <div class="sliders">
      <ng-container *ngFor="let img of img_list; let i = index">
        <div class="slider" *ngIf="current === i" @fade>
          <img
            (click)="manualSelect((i + 1) % this.img_list.length)"
            class="img"
            [src]="img.src"
            [alt]="img.alt" />
        </div>
      </ng-container>
    </div>
    <div *ngIf="img_list.length > 1" class="carousel-controls">
      <a
        *ngFor="let img of img_list; let i = index"
        (click)="manualSelect(i)"
        class="carousel-control carousel-control-{{ i }}"
        [class.active]="current === i"></a>
    </div>
  </div> `,
    styleUrls: ['./carousel.component.scss'],
    animations: [
        trigger('fade', [
            transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
            transition('* => void', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
        ]),
    ],
    standalone: false
})
export class CarouselComponent implements OnInit {
  // source: https://stackblitz.com/edit/angular-simple-carousel?embed=1&file=src/app/app.component.html&hideNavigation=1
  current = 0;
  @Input() img_list: { alt: string; src: string }[] = [];
  interval?: NodeJS.Timeout;
  intervalMs = 6000;

  ngOnInit() {
    this.interval = setInterval(() => {
      this.current = ++this.current % this.img_list.length;
    }, this.intervalMs);
  }

  manualSelect(index: number) {
    clearInterval(this.interval);
    this.current = index;
    this.interval = setInterval(() => {
      this.current = ++this.current % this.img_list.length;
    }, this.intervalMs);
  }
}
