<ca-header shape="rounded"></ca-header>
<ng-container *ngIf="detail$ | async as detail; else loader">
  <div class="carousel" *ngIf="gallerySources as gallery">
    <ca-carousel [img_list]="gallery"></ca-carousel>
  </div>

  <ng-container *ngIf="detail$ | async as detail">
    <div class="info cheese-detail">
      <h1>{{ detail.name }}</h1>
      <div style="display: contents" [innerHTML]="(detail$ | async)?.description"></div>
    </div>

    <ng-container *ngIf="relatedRecipes$ | async as relatedRecipes">
      <div class="related-recipes recipes-list">
        <h2 *ngIf="listTitle$ | async as title">{{ title }} {{ detail.name }}</h2>

        <a
          *ngFor="let recipe of relatedRecipes"
          class="recipes-list-item"
          [routerLink]="recipe.routerLink">
          <img [alt]="recipe.image.alt" [src]="base + recipe.image.src" />
          <div class="info">
            <h4>
              <span *ngFor="let t of recipe.recipeTypes">{{ t.name }} </span>
            </h4>
            <h3>{{ recipe.name }}</h3>
            <div class="cooking-time" *ngIf="recipe.preparationTime > 0">
              <span class="material-symbols-outlined">schedule</span>
              <p>{{ recipe.preparationTime }} min</p>
            </div>
          </div>
        </a>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loader>
  <!-- Insert template here -->
</ng-template>
