import { APP_INITIALIZER, NgModule, Provider, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { HomeComponent } from './pages/home/home.component';
import { CheesesListComponent } from './pages/cheeses-list/cheeses-list.component';
import { RecipesListComponent } from './pages/recipes-list/recipes-list.component';
import { MomentDetailComponent } from './pages/moment-detail/moment-detail.component';
import { CheeseDetailComponent } from './pages/cheese-detail/cheese-detail.component';
import { RecipeDetailComponent } from './pages/recipe-detail/recipe-detail.component';
import { SustainabilityComponent } from './pages/sustainability/sustainability.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ContactComponent } from './pages/contact/contact.component';
import { MenuComponent } from './components/menu/menu.component';
import { InitializerService } from './services/initializer/initializer.service';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ApiService } from './services/api.service';
import { StoreModule } from '@ngrx/store';
import { metaReducers, ROOT_REDUCER } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GeneralEffects } from './store/general.effects';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { PrivacyAndCookiesComponent } from './pages/privacy-and-cookies/privacy-and-cookies.component';
import { CommonModule } from '@angular/common';
import { HomeEffects } from './store/home.effects';
import { CheesesEffects } from './store/cheeses.effects';
import { CaNgCoreModule } from '@ca/ca-ng-core';
import { RecipesEffects } from './store/recipes.effects';
import { MomentsEffects } from './store/moments.effects';
import { SustainabilityEffects } from './store/sustainability.effects';
import { ContactEffects } from './store/contact.effects';
import { PrivacyPolicyEffects } from './store/privacy-policy.effects';
import { DisclaimerEffects } from './store/disclaimer.effects';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { SeoService } from './services/seo.service';

// export function initializeApp(appInitService: InitializerService, apiService:ApiService) {
//   return (): Promise<unknown> => {
//     return appInitService.init();
//   };
// }

export function initializeApp(): () => Promise<unknown> {
  const initializerService = inject(InitializerService);
  return () => initializerService.init();
}

export const AppInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  multi: true,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CheesesListComponent,
    RecipesListComponent,
    MomentDetailComponent,
    CheeseDetailComponent,
    RecipeDetailComponent,
    SustainabilityComponent,
    CarouselComponent,
    ContactComponent,
    MenuComponent,
    HeaderComponent,
    LandingComponent,
    DisclaimerComponent,
    PrivacyAndCookiesComponent,
    FormErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledNonBlocking' }),
    FormsModule,
    CaNgCoreModule,
    HttpClientModule,
    StoreModule.forRoot(ROOT_REDUCER, { metaReducers }),
    EffectsModule.forRoot([
      CheesesEffects,
      ContactEffects,
      DisclaimerEffects,
      GeneralEffects,
      HomeEffects,
      MomentsEffects,
      PrivacyPolicyEffects,
      RecipesEffects,
      SustainabilityEffects,
    ]),
    StoreDevtoolsModule.instrument({ connectInZone: true }),
  ],
  providers: [InitializerService, AppInitializerProvider, SeoService],
  bootstrap: [AppComponent],
})
export class AppModule {}
