import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { LandingState, DismissLanding } from '../../store/landing.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { selectLanding, selectLinks, selectRouterLinkHome } from '../../store/selectors';
import { Router } from '@angular/router';
import { GeneralState } from '../../store/general.reducer';
import { PageID } from '../../models';
import { environment } from '../../../environments/environment';
import { CaVideoDirective } from '@ca/ca-ng-core';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-landing',
  template: `<div class="hero-full" (click)="dismiss()">
    <video
      *ngIf="type === 'video'"
      caVideo
      [src]="base + src"
      autoplay
      playsinline
      preload="auto"></video>
    <img *ngIf="type === 'img'" [alt]="alt" [src]="src" />
  </div>`,
  standalone: false,
})
export class LandingComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(CaVideoDirective) video?: CaVideoDirective;
  protected src?: string;
  protected type?: 'img' | 'video';
  protected alt?: string;
  base = environment.apiBaseUrl;
  homeLink?: string;

  private sub = new CaSubscriber();
  constructor(
    private seo: SeoService,
    private router: Router,
    private store: Store<{ landing: LandingState; general: GeneralState }>
  ) {}
  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectRouterLinkHome), (home) => (this.homeLink = home));
    this.sub.subscribe(this.store.select(selectLanding), {
      next: (s) => {
        if (s.data) {
          if (!this.homeLink)
            this.sub.subscribe(this.store.select(selectLinks), {
              next: (links) => {
                if (s.dismissedMedia) {
                  const home = links.find((l) => l.id === PageID.HOME);
                  this.homeLink = '/' + home?.metaUrl;
                  if (home) this.router.navigate([this.homeLink]);
                }
              },
            });
          else if (s.dismissedMedia) this.router.navigate([this.homeLink]);
          this.seo.setMeta(s.data);

          if (s.data.banner?.video?.src) {
            this.type = 'video';
            this.src = s.data.banner?.video?.src;
            this.alt = s.data.banner?.video?.alt;
          } else if (s.data.banner?.image?.src) {
            this.type = 'img';
            this.src = s.data.banner?.image?.src;
            this.alt = s.data.banner?.video?.alt;
          }
        }
      },
    });
  }

  ngAfterViewInit() {
    if (this.video) {
      const onEnd = () => {
        this.store.dispatch(DismissLanding());
        if (this.homeLink) this.router.navigate([this.homeLink]);
      };
      this.video.element.onended = onEnd;
      const startPlayPromise = this.video.element.play();
      if (startPlayPromise !== undefined) {
        this.video.element.muted = true;
        startPlayPromise
          .then(() => {
            // Start whatever you need to do only after playback
            // has begun.
          })
          .catch((error) => {
            if (this.video) {
              if (error.name === 'NotAllowedError') {
                // showPlayButton(videoElem);
              } else {
                // Handle a load or playback error
              }
            }
          });
      }
    }
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  dismiss() {
    this.store.dispatch(DismissLanding());
    this.router.navigate([this.homeLink ?? '/home']);
  }
}
