import { createAction, createReducer, on } from '@ngrx/store';

export interface MenuState {
  open: boolean;
}
export const initialState: MenuState = { open: false };

export const openMenu = createAction('[Menu] open');
export const closeMenu = createAction('[Menu] close');
export const toggleMenu = createAction('[Menu] toggle');

export const reducer = createReducer(
  initialState,
  on(openMenu, () => ({ open: true })),
  on(closeMenu, () => ({ open: false })),
  on(toggleMenu, (_) => ({ open: !_.open }))
);
