import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadDisclaimer } from '../../store/disclaimer.reducer';
import { selectDisclaimerState, selectLanguage } from '../../store/selectors';
import { CaSubscriber } from '@ca/ca-utils';
import { GeneralState } from '../../store/general.reducer';
import { SeoService } from '../../services/seo.service';
import { PageDataBase } from '../../models';

@Component({
  selector: 'ca-disclaimer',
  template: `
    <ng-container *ngIf="data$ | async as page">
      <ca-header [media]="page.banner"></ca-header>
      <h1 *ngIf="page.title" class="privacy-title">{{ page.title }}</h1>

      <div *ngIf="page.text" [innerHTML]="page.text" class="privacy-content"></div>
    </ng-container>
  `,
  standalone: false,
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  data$ = this.store.select(selectDisclaimerState);

  constructor(
    private store: Store<{ general: GeneralState; disclaimer: PageDataBase }>,
    private seo: SeoService
  ) {}
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  ngOnInit(): void {
    this.sub.subscribe(this.data$, (d) => this.seo.setMeta(d));
    this.sub.subscribe(this.store.select(selectLanguage), () =>
      this.store.dispatch(LoadDisclaimer())
    );
  }
}
