import { Directive, ElementRef } from '@angular/core';
@Directive({
    selector: '[caVideo]',
    standalone: false
})
export class CaVideoDirective {
  public element: HTMLVideoElement;
  constructor(ref: ElementRef<HTMLVideoElement>) {
    this.element = ref.nativeElement;
  }
}
