import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SEO } from '../models';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private meta: Meta) {}

  setMeta(data?: { seo?: SEO }) {
    // TODO: set page url
    if (data?.seo) {
      if (data.seo.title) {
        this.meta.updateTag({ name: 'title', content: data.seo.title });
        this.meta.updateTag({ name: 'og:title', content: data.seo.title });
      }
      if (data.seo.description) {
        this.meta.updateTag({ name: 'description', content: data.seo.description });
        this.meta.updateTag({ name: 'og:description', content: data.seo.description });
      }
      if (data.seo.image) {
        this.meta.updateTag({
          name: 'og:image',
          content: environment.apiBaseUrl + data.seo.image.src,
        });
      }
    } else {
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="og:title"');
      this.meta.removeTag('name="description"');
      this.meta.removeTag('name="og:description"');
      this.meta.removeTag('name="og:image"');
    }
  }
}
