import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrorType } from '../../pages/contact/contact.component';
import { Store } from '@ngrx/store';
import { GeneralState } from '../../store/general.reducer';
import { selectTranslatedErrors } from '../../store/selectors';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-form-error',
  template: `
    <span *ngIf="translations && errorType" class="error">
      {{ translations[errorType] }}
    </span>
  `,
  standalone: false,
})
export class FormErrorComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  @Input() errorType?: ValidationErrorType | null = null;

  message: string | null = null;
  translations?: { [key: string]: string };
  translations$ = this.store.select(selectTranslatedErrors);

  constructor(private store: Store<{ general: GeneralState }>) {}

  ngOnInit(): void {
    this.sub.subscribe(this.translations$, (translations) => {
      this.translations = translations;
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
