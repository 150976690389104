import { createAction, createReducer, props, on } from '@ngrx/store';
import { General, GeneralData, LinkInfo, RouteInfo } from '../models';
import { Region, SupportedLanguage } from '../models';

export interface GeneralState {
  url?: string;
  metaUrl?: string;
  seo?: {
    title?: string;
    description?: string;
  };

  language: SupportedLanguage;
  region: Region;
  links: LinkInfo[];
  routes: RouteInfo[];

  translations: GeneralData['translates'];
  address: GeneralData['settings']['streetAddress'];
  facebook: GeneralData['settings']['facebook'];
  companyName: GeneralData['settings']['companyName'];
  instagram: GeneralData['settings']['instagram'];
  phone: GeneralData['settings']['phone'];
}

const initialState: GeneralState = {
  language: sessionStorage.getItem('language')
    ? (sessionStorage.getItem('language') as SupportedLanguage)
    : 'en',
  region: sessionStorage.getItem('region') ? (sessionStorage.getItem('region') as Region) : 'world',
  links: [],
  routes: [],
  address: {
    city: 'MOORSLEDE',
    postalCode: '8890',
    streetName: 'Stationstraat',
    streetNumber: '149',
  },
  companyName: 'Brugge Kaas',
  facebook: 'https://www.facebook.com/bruggekaas/',
  instagram: 'https://www.instagram.com/bruggekaas',
  phone: '+32 51 270 600',
  translations: {
    btnSend: 'Versturen',
    btnUpload: 'Uploaden',
    formErrorMessage: '',
    formSuccessMessage: '',
    lblAddressCity: 'Stad',
    lblAddressCountry: 'Land',
    lblAddressNumber: 'Nummer',
    lblAddressPostalCode: 'Postcode',
    lblAddressStreet: 'Straat',
    lblAttachment: 'Bijlage',
    lblEmail: 'E-Mail',
    lblFirstName: 'Voornaam',
    lblLastName: 'Achternaam',
    lblMessage: 'Bericht',
    lblPhone: 'Telefoon',
    lblSubject: 'Onderwerp',
    relatedRecipes: 'Recepten met',
    hintAttachment: 'Bijlage toevoegen',
    subjectOptionComplaints: 'Klachten',
    subjectOptionJobs: 'Jobs / Stage',
    subjectOptionOther: 'Andere',
    subjectOptionPress: 'Pers',
    subjectOptionProducts: 'Producten',
    subjectOptionSponsoring: 'Sponsoring',
    cheeseFilterLabel: 'Kies een kaas',
    momentFilterLabel: 'Kies een moment',
    //errors
    fileSizeError: 'Bestand te groot.',
    maxLengthError: 'Te lang.',
    minLengthError: 'Te kort.',
    isEmailError: 'Ongeldige e-mail.',
    isStringError: 'Moet text zijn',
    isAlphaError: 'a-zA-Z',
    cheeseSelectHint: '-- Kies een kaas --',
    momentSelectHint: '-- Kies een moment --',
    cheesesListTitle: 'Kazen',
    recipesListTitle: 'Recepten',
    countriesSelectHint: '-- Kies een land --',
    ingredientsLabel: 'Ingrediënten',
    preparationLabel: 'Bereidingswijze',
    downloadRecipeLabel: 'Recept downloaden',
    numberOfPersonsLabel: 'pers.',
  },
};

//#region ACTIONS
export enum GeneralActions {
  loadGeneralData = '[General] load',
  generalDataLoaded = '[General] loaded',
  generalDataFailure = '[General] failed to load',

  setLanguage = '[Language] set',
  setRegion = '[Region] set',
  appError = '[ERROR]',
  setURL = '[URL] set',
  setSeoTitle = '[SEO] set title',
  setSeoDescription = '[SEO] set description',
}
export const LoadGeneralData = createAction(GeneralActions.loadGeneralData);
export const GeneralDataLoaded = createAction(
  GeneralActions.generalDataLoaded,
  props<{ data: General }>()
);

export const GeneralDataFailed = createAction(
  GeneralActions.generalDataFailure,
  props<{ error: any }>()
);

export const SetLanguage = createAction(
  GeneralActions.setLanguage,
  props<{ language: SupportedLanguage }>()
);
export const SetRegion = createAction(GeneralActions.setRegion, props<{ region: Region }>());
export const AppError = createAction(GeneralActions.appError, props<{ error: any }>());

export const SetURL = createAction(GeneralActions.setURL, props<{ url: string }>());
export const SetSEOTitle = createAction(GeneralActions.setSeoTitle, props<{ content: string }>());
export const SetSeoDescription = createAction(
  GeneralActions.setSeoDescription,
  props<{ content: string }>()
);
//#endregion

export const reducer = createReducer(
  initialState,
  on(GeneralDataLoaded, (_, { data }) => ({
    ..._,
    links: data.pages.filter((p) => p.type === 'link') as LinkInfo[],
    routes: data.pages.filter((p) => p.type === 'route') as RouteInfo[],
    translations: data.translates,
  })),
  on(SetLanguage, (_, { language }) => ({
    // ...initialState,
    ..._,
    language,
  })),
  on(SetRegion, (_, { region }) => ({
    ..._,
    region,
  })),
  on(SetURL, (_, { url }) => ({
    ..._,
    url,
  })),
  on(SetSEOTitle, (_, { content }) => ({
    ..._,
    seo: {
      ..._.seo,
      title: content,
    },
  })),
  on(SetSeoDescription, (_, { content }) => ({
    ..._,
    seo: {
      ..._.seo,
      description: content,
    },
  }))
);
