import { Directive, Input, ViewContainerRef } from '@angular/core';

/**
 * Add caCutCellText to a table cell with string | string[] content to cut it.
 * usage:
 * ```
 * <td mat-cell *matCellDef="let element"
 *  caCutCellText
 *  [cellText]="element.columnName">
 *  {{ element.columnName }}
 * </td>
 * ```
 * Optionally specify ```maxCellTextLength```- defaults to 30 (chars)
 *
 */
@Directive({
    selector: '[caCutCellText]',
    standalone: false
})
export class CutCellTextDirective {
  private originalText!: string | string[];
  protected _text!: string;

  /**
   * Optional: set max number of chars in resulting text
   * BE SURE TO SET THIS BEFORE SETTING "cellText"
   */
  @Input() maxCellTextLength!: number;
  /**
   * Add caCutCellText to a table cell with string | string[] content to cut it.
   *
   * usage:
   * ```
   * <td mat-cell *matCellDef="let element"
   *  caCutCellText
   *  [cellText]="element.columnName">
   *  {{ element.columnName }}
   * </td>
   * ```
   *
   * Optionally specify ```maxCellTextLength```- defaults to 30 (chars)
   * BE SURE TO DO THIS BEFORE SETTING "cellText"
   */
  @Input() set cellText(value: string | string[]) {
    this.originalText = value ?? this.originalText;
    this._text = Array.isArray(value) ? value.join(', ') : value;
    this.sliceText();
    this.host.element.nativeElement.textContent = this._text;
  }

  private sliceText() {
    if (
      this._text !== null &&
      this._text !== undefined &&
      this._text.length > this.maxCellTextLength
    ) {
      this._text = this._text.slice(0, this.maxCellTextLength - 3) + '...';
    }
  }
  constructor(private host: ViewContainerRef) {
    this.originalText = this.host.element.nativeElement.textContent;
  }

  // private sliceText() {
  //   if (this._text.length > this.maxCellTextLength)
  //     this._text = this._text.slice(0, this.maxCellTextLength - 3) + '...';
  // }
}
