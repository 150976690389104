import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadRecipe, RecipesState } from '../../store/recipes.reducer';
import { Store } from '@ngrx/store';
import { CaSubscriber } from '@ca/ca-utils';
import {
  selectLanguage,
  selectRecipeLabels,
  selectRecipePage,
  selectRecipesRelatedToRecipe,
} from '../../store/selectors';
import { Banner, RecipeDetailPageData, SupportedLanguage, ImageData } from '../../models';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { GeneralState } from '../../store/general.reducer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as CookieConsent from 'vanilla-cookieconsent';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  standalone: false,
})
export class RecipeDetailComponent implements OnInit, OnDestroy {
  protected base = environment.apiBaseUrl;
  media?: Banner;
  private sub = new CaSubscriber();
  recipe?: RecipeDetailPageData['data'];
  // recipes?: RecipeListItemData[];
  relatedRecipes$ = this.store.select(selectRecipesRelatedToRecipe);
  labels$ = this.store.select(selectRecipeLabels);
  videoId?: string;
  videoUrl?: SafeResourceUrl;
  lang?: SupportedLanguage;
  gallerySources?: ImageData[];

  constructor(
    private seo: SeoService,
    private route: ActivatedRoute,
    private store: Store<{ general: GeneralState; recipes: RecipesState }>,
    private _sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    this.sub.subscribe(this.route.paramMap, (params) => {
      const metaUrl = params.get('metaUrl');
      if (metaUrl) this.store.dispatch(LoadRecipe({ metaUrl }));
      else throw new Error('No metaUrl found in route');
    });

    this.sub.subscribe(this.store.select(selectLanguage), (lang: SupportedLanguage) => {
      if (this.lang && this.recipe) {
        this.store.dispatch(LoadRecipe({ metaUrl: this.recipe.id }));
      }
      this.lang = lang;
    });

    this.sub.subscribe(this.store.select(selectRecipePage), ({ detail, relatedRecipes }) => {
      if (!detail || !relatedRecipes) return;

      this.recipe = detail?.data;
      this.media = detail?.data.image
        ? {
            image: {
              alt: detail.data.image.alt,
              src: detail.data.image.src,
            },
          }
        : undefined;
      this.gallerySources = detail?.data.gallery.map((i) => ({
        alt: i.alt,
        src: this.base + i.src,
      }));
      this.seo.setMeta(detail?.data);
      // video
      this.videoUrl = undefined;
      if (detail?.data.videoUrl) {
        this.videoId = getId(detail.data.videoUrl);
        if (this.videoId) {
          const allowYoutube =
            CookieConsent.acceptedService('youtube', 'analytics') ||
            CookieConsent.acceptedService('Youtube Embed', 'analytics');
          this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
            `https://www.youtube${allowYoutube ? '.com' : '-nocookie.com'}/embed/${this.videoId}`
          );
        } else throw new Error('No video ID');
      }
    });
  }
  // relatedRecipies$ = of(this.recipe).pipe(
  //   map((data) => (data ? this.store.select(selectRecipesRelatedToRecipe(data)) : undefined))
  // );
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}

function getId(url: string): string {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
}
