import { Component, OnDestroy, OnInit } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';
import { LoadCheeseDetail } from '../../store/cheeses.reducer';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Params } from '@angular/router';
import { CheeseDetailPageData, ImageData, RecipeListItemData } from '../../models';
import {
  selectCheeseDetail,
  selectLanguage,
  selectRecipesRelatedToCheese,
  selectRelatedRecipesTitle,
  selectRelatedRecipesToCheese,
} from '../../store/selectors';
import { environment } from '../../../environments/environment';
import { BruggeKaasState } from '../../store';
import { LoadRecipes } from '../../store/recipes.reducer';
import { SeoService } from '../../services/seo.service';
import { map } from 'rxjs';

@Component({
  selector: 'ca-cheese-detail',
  templateUrl: './cheese-detail.component.html',
  standalone: false,
})
export class CheeseDetailComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  data?: CheeseDetailPageData;
  gallerySources?: ImageData[];
  // recipes?: RecipeListItemData[];
  base = environment.apiBaseUrl;
  relatedRecipes$ = this.store.select(selectRelatedRecipesToCheese);
  listTitle$ = this.store.select(selectRelatedRecipesTitle);

  metaUrl$ = this.route.params.pipe(
    map((params) => ('metaUrl' in params ? params['metaUrl'] : undefined))
  );
  detail$ = this.store.select(selectCheeseDetail);

  constructor(
    private route: ActivatedRoute,
    private store: Store<BruggeKaasState>,
    private seo: SeoService
  ) {}

  ngOnInit(): void {
    this.sub.subscribe(this.metaUrl$, (metaUrl) => {
      if (metaUrl) this.store.dispatch(LoadCheeseDetail({ metaUrl }));
    });

    this.sub.subscribe(this.store.select(selectLanguage), () => {
      if (this.data) {
        this.store.dispatch(LoadRecipes());
        this.store.dispatch(LoadCheeseDetail({ metaUrl: this.data.id }));
      }
    });

    this.sub.subscribe(this.detail$, {
      next: (detail) => {
        this.data = detail;
        this.seo.setMeta(detail);
        this.gallerySources = detail?.gallery.map((i) => ({
          alt: i.alt,
          src: this.base + i.src,
        }));
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
